import React, { useEffect } from 'react';

const Deviare = () => {
  const restoreCognitoSession = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const idToken = urlParams.get('idToken');
    const accessToken = urlParams.get('accessToken');
    const refreshToken = urlParams.get('refreshToken');
    const userData = urlParams.get('userData');
    const lastAuthUser = urlParams.get('lastAuthUser');
    console.log('idToken', idToken);
    console.log('accessToken', accessToken);
    console.log('refreshToken', refreshToken);

    if (!idToken || !accessToken) {
      console.error('Missing Cognito session tokens. Redirecting to login.');
      // window.location.href = 'http://localhost:3000/login';
      return;
    }

    // Save tokens in localStorage with Cognito's expected key format
    const appId = '20738icdfn538kieuetqrajoj2'; // Replace with your actual Cognito App ID

    localStorage.setItem(
      `CognitoIdentityServiceProvider.${appId}.${lastAuthUser}.idToken`,
      idToken
    );
    localStorage.setItem(
      `CognitoIdentityServiceProvider.${appId}.${lastAuthUser}.accessToken`,
      accessToken
    );
    localStorage.setItem(
      `CognitoIdentityServiceProvider.${appId}.${lastAuthUser}.refreshToken`,
      refreshToken
    );
    localStorage.setItem(
      'CognitoIdentityServiceProvider.20738icdfn538kieuetqrajoj2.LastAuthUser',
      lastAuthUser
    );
    localStorage.setItem(
      `CognitoIdentityServiceProvider.${appId}.${lastAuthUser}.userData`,
      decodeURIComponent(userData)
    );

    // Redirect to '/' if all tokens are successfully stored
    const cleanUrl = window.location.origin + window.location.pathname;
    window.history.replaceState({}, document.title, cleanUrl);

    console.log('Cognito session restored.');
    window.location.href = '/'; // Redirect to home
  };

  useEffect(() => {
    restoreCognitoSession();
  }, []); // Add an empty dependency array to ensure it runs only once

  return <div></div>;
};

export default Deviare;
